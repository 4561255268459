import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BasicLayout,
  Footer,
  Card,
  Form,
  Input,
  Button,
  LoadingContent,
  Error,
} from 'src/common';
import { useQueryParams, useApi, useModel, useForm } from 'src/lib/hooks';
import products from 'src/products';

type CampaignFormData = {
  Code: string;
};

const Campaign = () => {
  const { code: campaignCode } = useQueryParams<{ code: string }>();
  const history = useHistory();
  const api = useApi();
  const { setQuote } = useModel.quote.dispatch();
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, control, formState, formError } = useForm();

  useLayoutEffect(() => {
    if (campaignCode) {
      setSubmitting(true);
      handleSetQuote({ Code: campaignCode });
    }
    // eslint-disable-next-line
  }, [campaignCode]);

  const handleSetQuote = async ({ Code: inviteCode }: CampaignFormData) => {
    const { data } = await api.get('/campaign', {
      params: { inviteCode },
    });

    const { ID, Premium, Property, Coverages, Messages } = data;

    const Phase = 'customer';
    const State = 'florida';
    const Product = 'ho6';
    products?.[State]?.[Product].preload();

    setQuote({
      FirstName: undefined,
      LastName: undefined,
      ID,
      Phase,
      Premium,
      State,
      Product,
      Property,
      Coverages,
      Messages,
    });

    history.replace('/onboarding');
  };
  const onSubmit = handleSubmit(handleSetQuote);

  const onGoBack = () => {
    history.push('/');
  };

  if (submitting) {
    return <LoadingContent />;
  }

  return (
    <BasicLayout>
      <Card title="Enter the campaign code">
        {formError && <Error>{formError}</Error>}
        <Form onSubmit={onSubmit}>
          <Input
            label="Campaign Code"
            error={control.getFieldError('Code')}
            {...register('Code', {
              required: {
                value: true,
                message: 'Campaign code is required.',
              },
            })}
            name="Code"
          />
        </Form>
      </Card>
      <Footer>
        <Button
          className="ml-4"
          loading={formState.isSubmitting}
          onClick={onSubmit}
        >
          SEND
        </Button>
        <Button onClick={onGoBack}>GO BACK</Button>
      </Footer>
    </BasicLayout>
  );
};

export default Campaign;
